import React from 'react'

interface ChartHoursProps {
    chartHourData: any;
    selectedTimeRange: 'day' | 'week' | 'month' | 'year';
}
export function convertHoursToWidth(hoursActivity: any): any {
    const [hours, minutes] = hoursActivity.split(' ').map((part: string) => parseInt(part) || 0);
    const totalHours = hours + (minutes / 60);
    const multiplier = 3.1; 
    return totalHours * multiplier;
}
export function convertHoursToHeight(hoursActivity: string): number {
    const timeParts = hoursActivity.split(' ').filter((part) => part.endsWith('h') || part.endsWith('m'));
    const hours = parseInt(timeParts[0]) || 0;
    const minutes = parseInt(timeParts[1]) || 0;
    const totalHours = hours + (minutes / 60);
    const multiplier = 9.4;
    return totalHours * multiplier;
}

function convertActiveDaysToHeight(activeHours: string): number {
    const [days, hours, minutes] = activeHours.split(' ').map((part: string) => parseInt(part) || 0);
    const totalHours = (days * 24) + hours + (minutes / 60);
    const multiplier = .52;
    return totalHours * multiplier;
}
export function convertWeeklyHoursToHeight(hoursActivity: string): number {
    const [hours, minutes] = hoursActivity.split(' ').map((part: string) => parseInt(part) || 0);
    const totalHours = hours + (minutes / 60);
    const multiplier = 1.5; 
    return totalHours * multiplier;
}
const ChartHours: React.FC<ChartHoursProps> = ({ chartHourData, selectedTimeRange }) => {
   
    return (
        <div>
            {selectedTimeRange === 'day' &&
            
                <div className='totalgraph'>
                    <div className='chartheader'>
                        <h3 style={{ color: 'white', fontWeight: 700, fontSize: '12px', opacity: '.8' }}>HOURS ACTIVITY</h3>
                        <p className="chartmsg">{chartHourData.message}</p>
                    </div>
             
                    <div className='hourchart' style={{ display: "flex", position: "absolute", justifyContent: "space-around", flexDirection: 'column', width: "100%", height: '100px' }}>
                        {['8h', '6h', '4h', '2h'].map((day: string, index: number) => {
                            return (
                                <span className = "datadayspan" key={index} data-test-id={`hours-${day}`} style={{ color: 'rgba(255, 255, 255, 1)', fontSize: '12px', fontWeight: 400, width: '100%', opacity: '30%' }}>
                                    {day}
                                </span>
                            );
                        })}
                    </div>
                    <div className='mr-10 hourDiv' style={{ display: 'flex', position: 'absolute', justifyContent: 'space-evenly', alignItems: 'flex-end', width: '100%', bottom: '0' }}>
              {[
                { label: 'Morning', hours: chartHourData.morning_hours || '0h 0m' },
                { label: 'Daytime', hours: chartHourData.daytime_hours || '0h 0m' },
                { label: 'Evening', hours: chartHourData.evening_hours || '0h 0m' },
              ].map((timePeriod, key) => {
               
                const [hourValue] = timePeriod.hours.split(' ')[0].split('h');
                const height = `${convertHoursToHeight(`${hourValue}h`)}px`;
               
                return (
                  <LineUp
                    data-test-id={`time-period-${key}`}
                    key={key}
                    height={height}
                    day={timePeriod.label}
                    backgroundColor="rgba(114, 239, 221, 1)"
                    width = "60px"
                  />
                );
              })}
            </div>
                </div>
            }
            {selectedTimeRange === 'week' &&
                <div className='totalgraph'>
                    <div className='chartheader'>
                        <h3 style={{ 
                            color: 'white', 
                            fontSize: '12px', 
                            opacity: '.8',
                            fontWeight: 700, }}>HOURS ACTIVITY</h3>
                        <p className="chartmsg">{chartHourData.message}</p>
                    </div>
                    <div className='hourchart' style={{ 
                        flexDirection: 'column',
                        width: "100%",
                        display: "flex",
                         position: "absolute",
                        justifyContent: "space-around",
                        height: '100px' }}>
                        {['24h', '18h', '12h', '6h'].map((day: string, index: number) => {
                            return (
                                <span  className = "dataweekspan" 
                                key={index} data-test-id={`hours-${day}`} 
                                style={{ 
                                    color: 'rgba(255, 255, 255, 1)', 
                                    fontWeight: 400, 
                                    width: '100%', 
                                    fontSize: '12px', 
                                    opacity: '30%' }}>
                                    {day}
                                </span>
                            );
                        })}
                    </div>
                    <div className='mr-10 hourDiv' style={{ display: "flex", paddingLeft: "15px" , position: "absolute", justifyContent: "space-evenly", alignItems: "flex-end", width: '100%', bottom: '0'}}>

                        {chartHourData.weekly_data && chartHourData.weekly_data.map((dayData: any, key: number) => {

                            const height = `${convertHoursToWidth(dayData.hours_activity)}px`;
                            const dayLabels = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
                            return (
                                <>

                                    <LineUp
                                        data-test-id={`day-${key}`}
                                        day={dayLabels[dayData.day_of_week]}
                                        backgroundColor="rgba(114, 239, 221, 1)"
                                        key={key}
                                        height={height}
                                         width = "20px"
                                    />
                                </>
                            );
                        })}
                    </div>
                </div>
            }

            {selectedTimeRange === 'month' &&

                <div className='totalgraph'>
                    <div className='chartheader'>
                        <h3 style={{ 
                            opacity: '.8',
                            color: 'white', 
                            fontWeight: 700,
                             fontSize: '12px',
                              }}>HOURS ACTIVITY</h3>
                        <p className="chartmsg">{chartHourData.message}</p>
                    </div>
                    <div className='hourchart' style={{ 
                        display: "flex", 
                        justifyContent: "space-around", position: "absolute",
                        flexDirection: 'column', width: "100%", height: '100px' }}>
                        {['40h', '30h', '20h', '10h'].map((day: string, index: number) => {
                            return (
                                <span data-test-id={`hours-${day}`} className = "datamonthspan"
                                 key={index} 
                                 
                                  style={{ 
                                    fontWeight: 400, 
                                    width: '100%', 
                                    opacity: '30%' ,
                                    color: 'rgba(255, 255, 255, 1)', 
                                    fontSize: '12px'
                                   }}>
                                    {day}
                                </span>
                            );
                        })}
                    </div>
                    <div className='mr-10 hourDiv' style={{ display: "flex", 
                        position: "absolute", 
                        justifyContent: "space-evenly", 
                        alignItems: "flex-end", 
                        width: '100%', bottom: '0',
                       }}>

                        {chartHourData.monthly_activity && chartHourData.monthly_activity.map((dayData: any, key: number) => {

                            const height = `${convertWeeklyHoursToHeight(dayData.active_hours)}px`;
                            const weekLabel = `Week ${key + 1}`;

                            return (
                                <>

                                    <LineUp
                                        data-test-id={`day-${key}`}
                                        key={key}
                                        height={height}
                                        width = "30px"
                                        backgroundColor="rgba(114, 239, 221, 1)"
                                        day={weekLabel}
                                    />
                                </>
                            );
                        })}
                    </div>
                </div>
            }
            {selectedTimeRange === 'year' &&

                <div className='totalgraph'>
                    <div className='chartheader'>
                        <h3 style={{  fontWeight: 700, color: 'white', 
                           
                            fontSize: '12px', 
                            opacity: '.8' }}>DAYS ACTIVITY</h3>
                        <p className="chartmsg">{chartHourData.message}</p>
                    </div>
                    <div className='hourchart' style={{ 
                         position: "absolute",
                         justifyContent: "space-around", 
                         flexDirection: 'column', 
                         width: "100%", 
                        display: "flex", 
                         height: '100px' }}>
                        {['4d', '3d', '2d', '1d'].map((day: string, index: number) => {
                            return (
                                <span className = "datayearspan" key={index} data-test-id={`hours-${day}`} style={{ color: 'rgba(255, 255, 255, 1)', fontSize: '12px', fontWeight: 400, width: '100%', opacity: '30%' }}>
                                    {day}
                                </span>
                            );
                        })}
                    </div>
                    <div className='mr-10 hourDiv' style={{ display: "flex", position: "absolute", justifyContent: "space-evenly", alignItems: "flex-end", width: '100%', bottom: '0' }}>

                        {chartHourData.yearly_activity && chartHourData.yearly_activity.map((dayData: any, key: number) => {

                            const height = `${convertActiveDaysToHeight(dayData.active_hours)}px`;

                            const monthName = dayData.month.split(",")[0][0].toUpperCase();
                            return (
                                <>

                                    <LineUp
                                        key={key}
                                        width = "15px"
                                        day={monthName}
                                        backgroundColor="rgba(114, 239, 221, 1)"
                                        data-test-id={`day-${key}`}
                                        height={height}
                                    />
                                </>
                            );
                        })}
                    </div>
                </div>
            }
        </div>
    );
}


interface LineUpProps {
    width?: string,
    height: string,
    backgroundColor?: string,
    key: number,
    day?: string

}


export function LineUp({ width , height, backgroundColor = "red", key, day }: LineUpProps) {
    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
            <div className="mr-10 hourDiv" key={key} style={{
                width,
                height,
                backgroundColor,
                borderRadius: "4px",

            }}></div>
            <div className="graphtext" style={{ fontSize: '12px', marginTop: '4px', color: 'rgb(211, 211, 211)', opacity: '.3', paddingTop: '10px', }}>{day}</div>
        </div>
    )
}


export default ChartHours