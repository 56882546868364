import React from 'react'
import CircleChart from './CircleChart'
import ChartHours from './ChartHours'

interface GraphChartProps {
    chartHourData: any;
    selectedTimeRange: 'day' | 'week' | 'month' | 'year';
  }


const GraphChart: React.FC<GraphChartProps> = ({ chartHourData, selectedTimeRange }) =>{
   
    let content: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding:'10px',
        minHeight:"auto"

    }
    let left: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        padding: "20px",
        width: '28%',
        alignItems: "center",
        border: '2px solid rgba(255,255,255,.1)',
        height:'186px'
    }
    let right: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        width: '70%',
        border: '2px solid rgba(255,255,255,.1)',
        padding:'20px',
        height:'186px'

    }
    return (
        // <div className='main' style={Main}>
          
            <div className='content' style={content}>
                <div className='left' style={left}>
                    <h3 style={{color:'white',fontWeight:700,fontSize:'12px', width: '100%', opacity: '.8'}}>GRADE AVERAGE</h3>
                   <div>
                   <CircleChart />
                 <div className='graphvalue'>   <span >80</span></div>
                    <div  className='chartgraph'>
                        <span className='graphstart'>0</span>
                     
                        <span className='graphend'>100</span>
                    </div>
                   </div>
                   
                  
                    <h3 className="graphtext" style={{color:'white',fontWeight:300,fontSize:'12px'}}>Solid Work keep Going</h3>
                </div>
                <div className='right p-2' style={right}>
                  
                    <div>
                      <div>
        {(selectedTimeRange === 'day' || selectedTimeRange === 'week' || selectedTimeRange === 'month' || selectedTimeRange === 'year') && (
          <ChartHours chartHourData={chartHourData} selectedTimeRange={selectedTimeRange} />
          
        )}
       
      </div>
                    </div>

                </div>
            </div>
       
    )
}
export default GraphChart