export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const neurology = require("../assets/neurology.png");
export const note_stack = require("../assets/note_stack.png");
export const Horn = require("../assets/Horn.png");
export const calendar_month = require("../assets/calendar_month.png");
export const graph = require("../assets/bar_chart_4_bars.png");
export const Bulb = require("../assets/skip_emoji_objects.png");
export const Home = require("../assets/skip_storefront.png");
export const Spacks = require("../assets/skip_Union.png");
export const medEdsmall = require("../assets/medED.png");
export const card1 = require("../assets/Card1.png");
export const card2 = require("../assets/Card2.png");
export const card3 = require("../assets/Card3.png");
export const filter = require("../assets/filter.png");
export const skip_icon1 = require("../assets/skip_icon(1).png");
export const skip_icon2 = require("../assets/skip_icon(2).png");
export const skip_icon3 = require("../assets/skip_icon(3).png");
export const skip_icon4 = require("../assets/skip_icon(4).png");
export const skip_icon5 = require("../assets/skip_icon(5).png");
export const skip_icon6 = require("../assets/skip_icon(6).png");
export const skip_icon7 = require("../assets/skip_icon(7).png");
export const skip_icon = require("../assets/skip_icon(0).png");
export const card4 = require("../assets/card4.jpeg");
export const card5 = require("../assets/card5.jpeg");
export const card6 = require("../assets/card6.jpeg");
export const question = require("../assets/question.png");
export const Radialbackground = require("../assets/Radialbackground.png");
export const summarize = require("../assets/summarize.png");
export const organ = require("../assets/organ.jpg");
export const exam = require("../assets/skip_exam.png");
export const prev = require("../assets/arrow_right.svg");
export const next = require("../assets/skip_arrow_right.svg");
export const firstBannerMed = require("../assets/image_banner.svg");
export const secondBannerMed = require("../assets/image_banner(1).svg");
export const thirdBannerMed = require("../assets/image_banner(2).svg");
export const forthBannerMed = require("../assets/image_banner(3).svg")
export const courseMed = require("../assets/image_course.svg")
export const AtoZ = require("../assets/skip_AZ.svg");
export const ZtoA = require("../assets/skip_ZA.svg");
export const low = require("../assets/low.svg");
export const high = require("../assets/high.svg")
export const calender2 = require("../assets/calender2.png");
export const tasknote = require("../assets/tasknote.png");
export const book = require("../assets/book.png");
export const backgroundSlider = require("../assets/background_left.svg");
export const skipIcon = require("../assets/skip_icon.svg");
export const bookLight = require("../assets/skip_book_2.svg");;
export const upwardCategory = require("../assets/upwardCategory.svg");
export const downwardCategory = require("../assets/downwardCategory.svg");
export const arrow_upward = require("../assets/arrow_upward.svg");
export const arrow_downward = require("../assets/arrow_downward.svg");
export const topone = require("../assets/topone.svg");
export const toptwo = require("../assets/toptwo.svg");
export const topthree = require("../assets/topthree.svg");
export const popular1 = require("../assets/popular1.png");
export const popular2 = require("../assets/popular2.png");
export const popular3 = require("../assets/popular3.png");
export const skipD = require("../assets/skip_d.svg");
export const skipE = require("../assets/skip_e.svg");
export const skipL = require("../assets/skip_l.svg");
export const arrowPrev = require('../assets/skip_expand_less.svg');
export const arrowNext = require('../assets/skip_expand_more.svg');
export const recommanded1 = require("../assets/recommand1.png");
export const recommanded2 = require("../assets/recommand2.png");
export const recommanded3 = require("../assets/recommand3.png");
export const skipLab = require("../assets/skip_lab.png");